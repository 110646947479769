/**
 * NOTE: please avoid adding rules here. Use styled components instead 
 */

 a:hover {
    color: unset;
    background-color:unset;

 }
 a {
  color: unset;
 
}

.logo {
  padding:unset;
}

.search-box  {
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
  max-width: 100vw;
  

}

.terminal-timeline:before, .terminal-timeline, .terminal-card:before {
  z-index:90;
}

body {
  --global-font-size: 15px;
  --global-line-height: 1.4em;
  --font-stack: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono,
    DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
  --background-color: #222225;
  --font-color: #e8e9ed;
  --invert-font-color: #222225;
  --secondary-color: #a3abba;
  --tertiary-color: #a3abba;
  --primary-color: #62c4ff;
  --error-color: #ff3c74;
  --progress-bar-background: #3f3f44;
  --progress-bar-fill: #62c4ff;
  --code-bg-color: #3f3f44;
  --input-style: solid;
  --display-h1-decoration: none;
  flex: 1;
    
}

